import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=0134579b&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('C:/Users/clong/repo/ml/src/components/Footer.vue').default})
