import Vue from 'vue';

import VueSlickCarousel from 'vue-slick-carousel';

import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default () => {
  Vue.component('VueSlickCarousel', VueSlickCarousel);
};
