import Vue from 'vue';

import ModalBase from './ModalBase.vue';

import 'animate.css';

export default (context, inject) => {
  inject('modal', (component, parent, componentData = {}) => {
    const node = document.createElement('div');
    document.body.appendChild(node);

    let vm = new Vue({
      el: node,
      name: `${component.name}Container`,
      parent,
      render: (h) => h(
        ModalBase,
        {
          ref: 'modalBase',
          props: componentData.props,
          on: {
            hide() {
              componentData.on?.hide?.();
              vm.$destroy();
              vm.$el.remove();
              vm = null;
            }
          }
        },
        [h(component, {
          ...componentData,
          on: {
            ...componentData.on,
            hide: () => {
              vm.$refs.modalBase.hide();
            }
          }
        })]
      )
    });

    vm.$nextTick(() => {
      vm.$refs.modalBase.show();
    });

    const api = {
      hide: () => {
        if (vm) {
          vm.$refs.modalBase.hide();
        }
        return api;
      }
    };

    return api;
  });
};
