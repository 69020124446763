
export default {
  data() {
    return {
      nav: [
        { to: '/about', label: 'About' },
        { to: '/practice-areas', label: 'Practice Areas' },
        { to: '#projects', label: 'Projects' },
        { to: '#work', label: 'Work With Us' },
        { to: '#contact', label: 'Contact' }
      ],

      socialNav: [
        { to: 'https://linkedin.com', label: 'About', icon: ['fab', 'linkedin-in'] },
        { to: 'https://twitter.com', label: 'About', icon: ['fab', 'twitter'] }
      ],

      headerHeight: 0,
      menuIsActive: false
    };
  },

  watch: {
    menuIsActive(val) {
      if (val) {
        document.documentElement.classList.add('no-scroll');
      }
      else {
        document.documentElement.classList.remove('no-scroll');
      }
    }
  },

  mounted() {
    this.onWindowResize();
    window.addEventListener('resize', this.onWindowResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  },

  methods: {
    onShowMenuBtnClick() {
      this.menuIsActive = !this.menuIsActive;
    },

    onMenuLinkFollowed() {
      this.menuIsActive = false;
    },

    onWindowResize() {
      const { headerEl } = this.$refs;
      const { screens } = this.$config;

      this.headerHeight = parseInt(getComputedStyle(headerEl).height || '0');

      if (this.menuIsActive && `${window.innerWidth}px` >= screens.lg) {
        this.menuIsActive = false;
      }
    }
  }
};
