
export default {
  methods: {
    /**
     * @param {HTMLElement} el
     */
    onTransitionEnter(el) {
      const width = getComputedStyle(el).width;

      el.style.width = width;
      el.style.position = 'absolute';
      el.style.visibility = 'hidden';
      el.style.height = 'auto';

      const height = getComputedStyle(el).height;

      el.style.width = null;
      el.style.position = null;
      el.style.visibility = null;
      el.style.height = '0';

      // Force repaint to make sure the animation is triggered correctly.
      getComputedStyle(el).height; // eslint-disable-line no-unused-expressions

      requestAnimationFrame(() => {
        el.style.height = height;
      });
    },

    /**
     * @param {HTMLElement} el
     */
    onTransitionAfterEnter(el) {
      el.style.height = 'auto';
    },

    /**
     * @param {HTMLElement} el
     */
    onTransitionLeave(el) {
      const height = getComputedStyle(el).height;

      el.style.height = height;

      // Force repaint to make sure the animation is triggered correctly.
      getComputedStyle(el).height; // eslint-disable-line no-unused-expressions

      requestAnimationFrame(() => {
        el.style.height = '0';
      });
    }
  }
};
